import React from "react";
import Carousel from "../components/carousel";

const SixthSection = () => {
  const images = [
    "./assets/images/OpenSea.svg",
    "./assets/images/raydium.svg",
    "./assets/images/solscan.png",
    "./assets/images/DEXTools.svg",
  ];
  return (
    <div className="bg-section10" id="about">
      <div className="bg-section11">
        <Carousel images={images} />
      </div>
    </div>
  );
};
export default SixthSection;

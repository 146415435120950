import Navbar from "../components/navbar";

const FirstSection = () => {
  return (
    <div className="bg-section1" id="home">
      <Navbar />
      <div className="flex flex-col-reverse lg:flex-row ">
        <div className="w-full lg:w-1/2 pt-14">
          <img
            src="./assets/images/pepe1.svg"
            alt="pepesuperman"
            className="md:min-w-[750px] md:min-h-[500px] "
          />
        </div>

        <div className="w-full lg:w-1/2 lg:mt-[0px] mt-[50px]  flex  flex-col justify-center items-center lg:items-start relative">
          <div className="flex justify-start items-start z-0">
            <img
              src="./assets/images/pepe2.svg"
              alt="pepe"
              className="lg:ml-[-200px] ml-0"
            />
          </div>

          <div className="CCM-bold lg:text-[64px] dm:text-[57px] sm:text-[50px] text-[40px] text-white leading-[24px] content-textWithShadow z-10">
            $MEMEVERSE
          </div>
          <div className="rocknroll-one-regular md:text-[24px] text-[20px] leading-[36px] text-white mt-[60px] w-full max-w-[550px] text-center lg:text-start">
            Discover the Epic Adventures of MemeVerse Heroes on Sol
            elit.
          </div>
          <div className="CCM-bold font-bold text-[20px] leading-[24px] mt-[60px] text-white">
            CONTRACT ADDRESS
          </div>
          <div className="bg-[#EFF5FF] rounded-[5px] border-[1px] border-black flex flex-col justify-between items-center px-[25px] py-[18px] mr-0 lg:mr-[20px] min-w-[300px]  mt-[22px]  gap-4 drop-shadow-[0_3px_1px_rgba(0,0,0,1)]">
            <div className="CCM-bold font-bold text-[15px] sm:text-[20px]  leading-[24px] text-black w-[200px] lg:w-[350px] xl:w-[500px] 2xl:w-[610px] break-words">
              C5UTWokJSJbtrsm2Qg1n9TRbdQVeURS5T8GHVfUdhr3V
            </div>
            <div className="rounded-[5px] border-[1px] border-black bg-[#83E445] CCM-bold font-bold text-[20px] leading-[24px] text-white px-[48px] py-[7px] btn-textWithShadow max-w-[150px] hover:cursor-pointer hover:bg-green-700">
              COPY
            </div>
          </div>
          <div className="flex justify-center items-center rounded-[5px] border-[1px] border-black bg-[#83E445] CCM-bold font-bold text-[20px] leading-[24px] text-white text-center py-[18px] mt-[30px] w-[250px] h-[60px] btn-textWithShadow  hover:cursor-pointer hover:bg-green-700">
            <a
              href="https://www.dextools.io/app/en/solana/pair-explorer/6w9n9ZVzZVoKQTpPi2zup4wsxdZRDV2mYR1j9QKQxzmi?t=1722803504762"
              target="_blank"
            >
              Buy on DexTools.io
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FirstSection;

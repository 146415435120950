const FourthSection = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="CCM-bold font-bold lg:text-[64px] dm:text-[57px] sm:text-[50px] text-[40px] leading-[79px] text-white content-textWithShadow">
        FIND US
      </div>
      <img src="./assets/images/raydium.svg" alt="raydium" />
      <div className="rocknroll-one-regular  text-[16px] sm:text-[18px] dm:text-[22px] lg:text-[24px] leading-[36px] text-white mt-[20px] mx-[50px] lg:mx-0 text-center max-w-[900px]">
        Don't miss your chance to be part of this epic adventure—where every
        meme has a story, and every laugh is a victory. Suit up, meme warriors,
        and let’s conquer the blockchain together!
      </div>
      <div className="rounded-[5px] border-[1px] border-black bg-[#83E445] CCM-bold font-bold text-[20px] leading-[24px] text-white text-center py-[18px] mb-[60px] mt-[90px] w-[250px] h-[60px] btn-textWithShadow hover:cursor-pointer hover:bg-green-700">
        CHART
      </div>
    </div>
  );
};
export default FourthSection;

import React, { useState } from "react";

const Navbar = () => {
  const [menu, setMenu] = useState(false);
  const clkMenu = () => {
    if (menu) setMenu(false);
    else setMenu(true);
  };
  return (
    <nav>
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between ">
          <div className="hidden sm:flex gap-[10px] ">
            <img src="./assets/images/logo-pepe.svg" alt="logo-pepe"></img>
            <div className="hidden md:flex justify-center items-center">
              <span className="CCM-bold text-[#ffcc00] logo-textWithShadow">
                MEMEVERSE
              </span>
            </div>
          </div>
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={() => clkMenu()}
            >
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">Open main menu</span>

              <svg
                className="block h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>

              <svg
                className="hidden h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-center">
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4 CCM-bold text-[20px] font-bold leading-[24px]">
                <a
                  href="#home"
                  className="rounded-md text-white  hover:bg-gray-700 px-3 py-2 text-sm font-medium hover:text-yellow-100 hover:cursor-pointer"
                  aria-current="page"
                >
                  HOME
                </a>
                <a
                  href="#about"
                  className="rounded-md px-3 py-2 text-sm  text-white hover:bg-gray-700 hover:text-yellow-100 hover:cursor-pointer"
                >
                  ABOUT
                </a>
                <a
                  href="#chart"
                  className="rounded-md px-3 py-2 text-sm  text-white hover:bg-gray-700 hover:text-yellow-100 hover:cursor-pointer"
                >
                  CHART
                </a>
                <a
                  href="#community"
                  className="rounded-md px-3 py-2 text-sm  text-white hover:bg-gray-700 hover:text-yellow-100 hover:cursor-pointer"
                >
                  COMMUNITY
                </a>
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div className="relative ml-3">
              <div className="flex gap-[20px]">
                <a
                  className=" bg-[#FFFFFF] rounded-[5px] w-[25px] h-[25px] p-[6px] hover:bg-gray-400 hover:cursor-pointer"
                  href="https://t.me/+41SP3lSVtzs0ZWNh"
                >
                  <img
                    src="./assets/images/telegram.svg"
                    alt="tel"
                    className=""
                  />
                </a>
                <a
                  className=" bg-[#FFFFFF] rounded-[5px] w-[25px] h-[25px] p-[6px] hover:bg-gray-400 hover:cursor-pointer"
                  href="https://x.com/memeverseonsol"
                >
                  <img
                    src="./assets/images/twitter.svg"
                    alt="tel"
                    className=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={menu ? "sm:hidden" : "hidden"} id="mobile-menu">
        <div className="space-y-1 px-2 pb-3 pt-2 CCM-bold ">
          <a
            href="#home"
            className="block rounded-md bg-gray-900 px-3 py-2 text-base  CCM-bold text-white"
            aria-current="page"
          >
            HOME
          </a>
          <a
            href="#about"
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            ABOUT
          </a>
          <a
            href="#chart"
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            CHART
          </a>
          <a
            href="#community"
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            COMMUNITY
          </a>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;

import FifthSection from "./fifthsection";
import FourthSection from "./fourthsection";
import ThirdSection from "./thirdsection";
import Footer from "../components/footer";

const SecondSection = () => {
  return (
    <div className="bg-section2">
      <div className="grid grid-cols-1 lg:grid-cols-2 ">
        <div className="flex flex-col justify-center items-center mt-[70px] lg:text-start text-center">
          <div className="CCM-bold max-w-[429px] font-bold lg:text-[64px] dm:text-[57px]  sm:text-[50px] text-[40px] leading-[79px] text-white content-textWithShadow">
            The legend Continues
          </div>
          <div className="rocknroll-one-regular md:text-[24px] text-[20px] leading-[36px] text-white mt-[25px] max-w-[500px] px-[25px]">
            Pepe and your favorite memes suit up for an epic showdown on Solana!
            <br />
            From the MemeVerse, these iconic heroes are ready to conquer the
            blockchain with their unique powers and unforgettable humor.
            <br />
            Brace yourself for a thrilling adventure where memes become legends,
            battles are fought with laughter, and the fate of Solana rests in
            the hands of our hilarious heroes.
            <br />
            Get ready to join the MemeVerse revolution!
          </div>
          <div className="rounded-[5px] border-[1px] border-black bg-[#83E445] CCM-bold font-bold text-[20px] leading-[24px] text-white text-center py-[18px] mt-[55px] w-[250px] h-[60px] lg:ml-[-170px] ml-0 btn-textWithShadow hover:cursor-pointer hover:bg-green-700">
            <a
              href="https://www.dextools.io/app/en/solana/pair-explorer/6w9n9ZVzZVoKQTpPi2zup4wsxdZRDV2mYR1j9QKQxzmi?t=1722803504762"
              target="_blank"
            >
              Buy $Memeverse
            </a>
          </div>
        </div>
        <div className="flex justify-center items-end mt-[60px]">
          <img
            src="./assets/images/pepe3.svg"
            alt="pepesuperman"
            className="lg:w-[525px] dm:w-[500px] sm:w-[450px] w-[300px] "
          />
        </div>
      </div>

      <div className="z-10 mb-[200px] relative">
        <img
          src="./assets/images/roadmap/pepe6.svg"
          alt="pepe"
          className="absolute bottom-0 left-0"
        />
        <img
          src="./assets/images/roadmap/pepe5.svg"
          alt="pepe"
          className="hidden lg:block absolute top-1/2 right-[180px]"
        />
        <FifthSection />
      </div>
      <div className=" bg-section4 relative mt-[100px]">
        <img
          src="./assets/images/pepe4.svg"
          alt="bg"
          className="absolute right-[0px] bottom-[0px]  z-[0]"
        />
        <img
          src="./assets/images/pepe5.svg"
          alt="bg"
          className="relative lg:absolute left-[0px] bottom-[0px]  z-[0]"
        />

        <div className="flex flex-col justify-center items-center">
          <div className="mt-[-100px]">
            <ThirdSection />
          </div>

          <div className="mt-[60px] z-[10] ">
            <FourthSection />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SecondSection;

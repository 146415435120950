import ScrollButton from "../components/scrollbutton";
import FirstSection from "../section/firstsection";
import SecondSection from "../section/secondsection";
import SixthSection from "../section/sixthsection";

const Landing = () => {
  return (
    <div className="flex flex-col">
      <FirstSection />
      <SixthSection />
      <SecondSection />
      <ScrollButton />
    </div>
  );
};

export default Landing;

import "./App.css";
import ScrollButton from "./components/scrollbutton";
import Landing from "./landing/landing";

function App() {
  return (
    <div className="App">
      <Landing />
      
    </div>
  );
}

export default App;

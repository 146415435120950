const FifthSection = () => {
  return (
    <div className="bg-section6 mb-[100px]" id="chart">
      <div className="bg-section7 ">
        {/* <img
          src="./assets/images/roadmap/bg-section8.svg"
          alt="bg"
          className="w-full z-3 absolute top-0 left-0"
        />{" "}
        <img
          src="./assets/images/roadmap/bg-section9.svg"
          alt="bg"
          className="w-full z-4 absolute top-0 left-0"
        /> */}
        <div className="bg-section8 ">
          <div className="bg-section2 flex flex-col justify-center items-center">
            <div className="CCM-bold dm:text-[57px] sm:text-[46px] text-center lg:text-[64px] text-[40px] text-white leading-[70px] content-textWithShadow max-w-[939px]  mt-[80px]">
              Memeverse Tokenomics
            </div>
            <div className="rocknroll-one-regular md:text-[24px] text-[20px] leading-[36px] text-white mt-[60px] w-full max-w-[800px] text-center lg:text-start">
              800M Supply - Yes, a supply fit for our heroes!
              <br />
              <br />
              1% Buy/Sell Tax - Just like any great universe, the MemeVerse
              needs funds to capture the hearts of many!
              <br />
              <br />
              Contract Renounced - No funny business here. In the MemeVerse,
              control belongs to the people!
              <br />
              <br />
              Liquidity Burn - Ensuring the people of the MemeVerse enjoy max
              level trust and sustainability!
            </div>
            <div className="grid grid-cols-1 gap-5  md:grid-cols-2 lg:grid-cols-4">
              <div className="relative flex flex-col gap-44">
                <div className="flex flex-col justify-end items-center h-[200px]">
                  <div className="CCM-bold text-[28px] leading-[79px] text-white">
                    1.0B Supply
                  </div>
                  <div className="rocknroll-one-regular text-white text-center text-[16px] leading-[24px] max-w-[350px]">
                    No funky numbers. Straight up 1 Billion supply, same as our
                    first target. 
                  </div>
                </div>
                <div className="h-[200px] md:block relative hidden">
                  <img
                    src="./assets/images/roadmap/roadmap1.png"
                    className="absolute top-0 transform -translate-x-1/2 -translate-y-[90px] left-1/2 w-[110px] h-[110px]"
                  ></img>
                  <img
                    src="./assets/images/roadmap/roadmapline.png"
                    alt="line"
                    className=" absolute top-1/4 transform -translate-x-[-80px] -translate-y-[90px] left-1/2 w-[2px];"
                  ></img>
                </div>
              </div>
              <div className="flex flex-col justify-end gap-44">
                <div className="relative hidden md:block">
                  <img
                    src="./assets/images/roadmap/roadmap2.png"
                    className="absolute top-0 transform -translate-x-1/2 translate-y-[85px] left-1/2"
                  ></img>
                  <img
                    src="./assets/images/roadmap/roadmapline.png"
                    alt="line"
                    className="hidden lg:block absolute top-[140px] right-[-112px] "
                  ></img>
                </div>
                <div className="relative flex h-[200px] flex-col justify-end items-center ">
                  <div className="CCM-bold text-[28px] leading-[79px] text-white">
                    2.Zero Tax
                  </div>
                  <div className="rocknroll-one-regular text-white text-center text-[16px] leading-[24px] max-w-[350px]">
                    Zero Tax, so you can buy more Memeverse... or weed.
                    <br />
                    Rich degens don't pay taxes.
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-44">
                <div className="relative flex h-[200px] flex-col justify-end items-center">
                  <div className="CCM-bold text-[28px] leading-[79px] text-white">
                    3.Renounced
                  </div>
                  <div className="rocknroll-one-regular text-white text-center text-[16px] leading-[24px] max-w-[350px]">
                    Renounced contract, safu degening. Freeze and mint are
                    revoked. 
                  </div>
                </div>
                <div className="h-[200px] md:block hidden relative">
                  <img
                    src="./assets/images/roadmap/roadmap3.png"
                    className="absolute top-0 transform -translate-x-1/2 -translate-y-[90px] left-1/2"
                  ></img>
                  <img
                    src="./assets/images/roadmap/roadmapline.png"
                    alt="line"
                    className=" absolute top-[-36px] right-[-112px]"
                  ></img>
                </div>
              </div>
              <div className="flex flex-col justify-end gap-44">
                <div className="relative hidden md:block">
                  <img
                    src="./assets/images/roadmap/roadmap4.png"
                    className="absolute top-0 transform -translate-x-1/2 translate-y-[85px] left-1/2"
                  ></img>
                </div>
                <div className="relative flex h-[200px] flex-col justify-end  items-center ">
                  <div className="CCM-bold text-[28px] leading-[79px] text-white truncate w-[320px] lg:w-[200px] text-center 2xl:w-[400px]">
                    4.Liquidity Burned
                  </div>
                  <div className="rocknroll-one-regular text-white text-center text-[16px] leading-[24px] max-w-[350px]">
                    liquidity has been burned to ensure max chad-level trust and
                    sustainability. 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FifthSection;

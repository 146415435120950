const ThirdSection = () => {
  return (
    <div
      className="border-white border-[5px] rounded-[20px] bg-[#0185A8] flex flex-col justify-center items-center max-w-[1200px] lg:w-[1000px] dm:w-[800px] sm:w-[600px] w-[300px]  px-[24px] pb-[62px] mx-0 lg:mx-0"
      id="community"
    >
      <div className="CCM-bold font-bold text-[40px] sm:text-[50] dm:text-[55px] lg:text-[64px] leading-[79px] text-white content-textWithShadow mt-[60px] text-center">
        Join our community
      </div>
      <div className="rocknroll-one-regular text-[16px] sm:text-[18px] dm:text-[22px] lg:text-[24px] leading-[36px] text-white mt-[40px] max-w-[900px] px-[50px] text-center ">
        Join the MemeVerse and dive into a world where your favorite memes
        become legendary superheroes! <br />
        Imagine Super Pepe leading the charge, battling villains and spreading
        laughter across the Solana universe Orci.
      </div>
      <div className="flex flex-col lg:flex-row justify-between items-center gap-[15px] mt-[90px]">
        <div className="rounded-[5px] border-[1px] border-black bg-[#83E445] CCM-bold font-bold lg:text-[20px] md:text-[16px] text-[12px] leading-[24px] text-white text-center px-[25px] py-[18px]   max-w-[250px] btn-textWithShadow hover:cursor-pointer hover:bg-green-700">
          <a
            href="https://www.dextools.io/app/en/solana/pair-explorer/6w9n9ZVzZVoKQTpPi2zup4wsxdZRDV2mYR1j9QKQxzmi?t=1722803504762"
            target="_blank"
          >
            Buy $Memeverse
          </a>
        </div>
        <div className="flex gap-[15px]">
          <a
            className=" bg-[#ffffff] rounded-[5px] p-[6px] hover:bg-gray-400"
            href="https://t.me/+41SP3lSVtzs0ZWNh"
          >
            <img
              src="./assets/images/telegram.svg"
              alt="tel"
              className="w-[48px] h-[48px] hover:cursor-pointer"
            />
          </a>
          <a
            className=" bg-[#ffffff] rounded-[5px]  p-[6px]  hover:bg-gray-400"
            href="https://x.com/memeverseonsol"
          >
            <img
              src="./assets/images/twitter.svg"
              alt="tel"
              className="w-[48px] h-[48px] hover:cursor-pointer"
            />
          </a>
        </div>
      </div>
    </div>
  );
};
export default ThirdSection;

const Footer = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="rocknroll-one-regular text-white text-[12px] leading-[18px] py-[22px]">
        © Copyright 2024 Memeverse. All Rights Reserved
      </div>
    </div>
  );
};

export default Footer;
